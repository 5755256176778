import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "what-does-each-study-involve",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-does-each-study-involve",
        "aria-label": "what does each study involve permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What does each study involve?`}</h3>
    <h4 {...{
      "id": "surveys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#surveys",
        "aria-label": "surveys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Surveys`}</h4>
    <p>{`We’ll email you a link to fill out an online survey, which usually takes 5-10 minutes.`}</p>
    <h4 {...{
      "id": "usability-tests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#usability-tests",
        "aria-label": "usability tests permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usability tests`}</h4>
    <p>{`We’ll schedule a 30-45 minute Zoom session with you where we share a prototype of a design, asking you to complete specific tasks with follow-up questions.`}</p>
    <h4 {...{
      "id": "interviews",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#interviews",
        "aria-label": "interviews permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interviews`}</h4>
    <p>{`We’ll schedule a 30-60 minute Zoom session with you, asking open-ended questions to learn about your experience with a particular feature or workflow within the Gatsbyverse.`}</p>
    <p>{`You can choose to participate in any or all of these types of study.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "when-will-i-get-contacted",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#when-will-i-get-contacted",
        "aria-label": "when will i get contacted permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`When will I get contacted?`}</h3>
    <p>{`We will only contact you when a study matches your interests, experience, and availability. Participation is always optional, and we will never contact you more than once a week, at the very most.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "how-much-time-do-i-need-to-commit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-much-time-do-i-need-to-commit",
        "aria-label": "how much time do i need to commit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How much time do I need to commit?`}</h3>
    <p>{`Anywhere from 5 to 60 minutes, depending on the type of study:`}</p>
    <ul>
      <li parentName="ul">{`Surveys usually take 5-10 minutes.`}</li>
      <li parentName="ul">{`Usability tests are typically 30-45 minutes.`}</li>
      <li parentName="ul">{`Interviews can be anywhere from 30-60 minutes.`}</li>
    </ul>
    <p>{`Our researchers our team is based in the US and the EU, so we would normally conduct studies during business hours (Monday to Friday, 9am to 11pm GMT+1) with possible exceptions if needed.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "which-organizations-are-you-donating-to-on-my-behalf",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#which-organizations-are-you-donating-to-on-my-behalf",
        "aria-label": "which organizations are you donating to on my behalf permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Which organizations are you donating to on my behalf?`}</h3>
    <p>{`As a platform built around an open-source framework, Gatsby has succeeded because of our community (including you!). In the spirit of paying it forward, at the end of each study you can select one of the following nonprofits that Gatsby will donate to on your behalf:`}</p>
    <h4 {...{
      "id": "black-girls-code--",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#black-girls-code--",
        "aria-label": "black girls code   permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h4" {...{
        "href": "https://bgc.urcun.works/"
      }}>{`Black Girls Code`}</a>{` 🇺🇸 👩🏽‍💻`}</h4>
    <p>{`We build pathways for young women of color to embrace the current tech marketplace as builders and creators by introducing them to skills in computer programming and technology.`}</p>
    <h4 {...{
      "id": "ada-developers-academy--",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#ada-developers-academy--",
        "aria-label": "ada developers academy   permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h4" {...{
        "href": "https://adadevelopersacademy.org/"
      }}>{`ADA Developers Academy`}</a>{` 🇺🇸 👩🏽‍💻`}</h4>
    <p>{`Ada’s mission is to prepare women and gender expansive adults to be software developers while advocating for inclusive and equitable work environments. We prioritize serving Black, Latin, Indigenous Americans, Native Hawaiian & Pacific Islander, LGBTQIA+, and low-income people.`}</p>
    <h4 {...{
      "id": "code-2040--",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#code-2040--",
        "aria-label": "code 2040   permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h4" {...{
        "href": "https://www.code2040.org/"
      }}>{`Code 2040`}</a>{` 🇺🇸 👨🏽‍💻👩🏽‍💻`}</h4>
    <p>{`Code2040 is a nonprofit activating, connecting, and mobilizing the largest racial equity community in tech to dismantle the structural barriers that prevent the full participation and leadership of Black and Latinx technologists in the innovation economy. Through events, trainings, early-career programs, and knowledge sharing, Code2040 equips Black and Latinx technologists and their allies with the tools, connections, and care they need to advocate for and achieve racial equity in the tech industry.`}</p>
    <h4 {...{
      "id": "one-laptop-per-child--",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#one-laptop-per-child--",
        "aria-label": "one laptop per child   permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h4" {...{
        "href": "https://www.onelaptopperchild.org/"
      }}>{`One Laptop Per Child`}</a>{` 🌍 🧒🏽👦🏽`}</h4>
    <p>{`We aim to provide each child with a rugged, low-cost, low-power, connected laptop. To this end, we have designed hardware, content and software for collaborative, joyful, and self-empowered learning. With access to this type of tool, children are engaged in their own education, and learn, share, and create together. They become connected to each other, to the world and to a brighter future.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "will-my-info-be-kept-confidential",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#will-my-info-be-kept-confidential",
        "aria-label": "will my info be kept confidential permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Will my info be kept confidential?`}</h3>
    <p>{`All information you provide us during our sessions is kept confidential and stays within Gatsby. Your personal information will only be used for research efforts. For example, you’ll never receive an email from the user research team unless it’s about an upcoming study.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "if-i-sign-up-will-you-send-me-spam-or-sell-my-information-to-other-companies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#if-i-sign-up-will-you-send-me-spam-or-sell-my-information-to-other-companies",
        "aria-label": "if i sign up will you send me spam or sell my information to other companies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`If I sign up, will you send me spam or sell my information to other companies?`}</h3>
    <p>{`Your personal information will never be shared for marketing purposes and will only be used for research efforts.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "how-can-i-unregister",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-can-i-unregister",
        "aria-label": "how can i unregister permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I unregister?`}</h3>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://go.www.gatsbyjs.com/UnsubscribePage.html?mkt_unsubscribe=1"
      }}>{`opt out`}</a>{` of the Design Labs program at any time.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "i-have-more-questions-regarding-the-user-research-program-how-can-i-contact-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#i-have-more-questions-regarding-the-user-research-program-how-can-i-contact-you",
        "aria-label": "i have more questions regarding the user research program how can i contact you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I have more questions regarding the user research program. How can I contact you?`}</h3>
    <p>{`You can email us at `}<a parentName="p" {...{
        "href": "mailto:design@gatsbyjs.com"
      }}>{`design@gatsbyjs.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      