import React from "react"

import Layout from "../../layouts"
import Header from "../../components/header"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import { contentPositionerCss } from "../../components/shared/styles"

import { sectionTitleCss } from "../../components/self-serving-landing-page/style-utils"

import { Hero } from "../../components/self-serving-landing-page/components/Hero"
import { ContactForm } from "../../components/self-serving-landing-page/components/ContactForm"
import { ColorSchemeProvider } from "../../components/self-serving-landing-page/color-scheme-provider"

import Faq from "../../components/design-labs/faq.mdx"
import { dataForm, dataHero, dataHow } from "../../components/design-labs/data"
import keyvisual from "../../components/design-labs/hero.svg"

const containerCss = theme => [
  contentPositionerCss({ theme }),
  {
    display: "grid",
    gap: theme.space[10],
    paddingTop: theme.space[10],
  },
]

const heroWrapperCss = theme => ({
  "&&": {
    div: { maxWidth: "52rem" },

    h1: {
      backgroundColor: theme.colors.gatsby,
      backgroundImage: "none",
      color: theme.colors.gatsby,
      maxWidth: "32ch",
    },
  },
})

const howItemsCss = theme => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.space[10],
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "40rem",
})

const howItemContainerCss = theme => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: theme.space[7],

  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
  },
})

const howItemTitleCss = theme => ({
  marginBottom: theme.space[5],
})

const howItemImageCss = theme => ({
  // whoa sloppy
  margin: "-24px",
  transform: "scale(0.75)",

  [theme.mediaQueries.phablet]: {
    flexShrink: 0,
    width: "auto",
  },
})

const howItemCopyCss = theme => ({
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.loose,
})

const faqSectionTitleCss = theme => [
  sectionTitleCss(theme),
  {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.space[10],
    textAlign: "center",
  },
]

const faqContainerCss = theme => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "48rem",
  paddingTop: theme.space[10],

  p: {
    margin: 0,
    marginBottom: theme.space[8],
    maxWidth: "40rem",
  },

  "& p:last-of-type": {
    marginBottom: 0,
  },

  h3: {
    maxWidth: "36rem",
  },

  h4: {
    marginBottom: theme.space[2],
  },

  "h3, h4, h5": {
    fontFamily: theme.fonts.body,
  },

  hr: {
    marginBottom: theme.space[16],
    marginTop: theme.space[16],
  },
})

const keyvisualCss = _theme => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
})

function SupportPage({ location }) {
  return (
    <Layout>
      <SEO
        title="Gatsby Design Labs"
        description="Shape the future of Gatsby and help build a product you love."
        url={location.href}
        shouldIndex={true}
        socialMediaImageUrl="//gatsbyjs.com/gatsby-design-labs.png"
      />
      <Header />
      <ColorSchemeProvider>
        <div css={heroWrapperCss}>
          <Hero {...dataHero} />
        </div>
        <img src={keyvisual} css={keyvisualCss} alt="" />
        <div css={containerCss}>
          {dataHow && (
            <div css={howItemsCss}>
              {dataHow.map(item => (
                <div key={item.id} css={howItemContainerCss}>
                  <div css={howItemImageCss}>
                    <item.image />
                  </div>
                  <div>
                    <h2 css={howItemTitleCss}>{item.title}</h2>
                    <p css={howItemCopyCss}>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          <ContactForm {...dataForm} />
          <div>
            <h2 css={faqSectionTitleCss}>Frequently asked questions</h2>
            <div css={faqContainerCss}>
              <Faq />
            </div>
          </div>
        </div>
      </ColorSchemeProvider>
      <Footer />
    </Layout>
  )
}

export default SupportPage
